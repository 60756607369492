<template>
  <div class="vm-modal">
    <p v-html="$t('configuration.paywayGroups.alior.aliorModal.text1')"></p>
    <p v-html="$t('configuration.paywayGroups.alior.aliorModal.text2')"></p>
  </div>
</template>

<script>
export default {
  name: 'AliorModal'
}
</script>
